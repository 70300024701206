'use client'

import clsx from 'clsx'

import { ArrowVertical } from '../Icons/ArrowVertical'

export function ScrollTopButton() {
  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <button
      onClick={handleClick}
      className={clsx(
        'text-blue-600 transition-colors hover:text-blue-700',
        'inline-flex items-center',
      )}
    >
      <span className="mr-1">Scroll back up</span>
      <ArrowVertical />
    </button>
  )
}
