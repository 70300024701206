'use client'

import dynamic from 'next/dynamic'

import { Skeleton } from '../Skeleton'

import type { Props as LocalizedPricingLabelProps } from './LocalizedPricingLabel'

/**
 * This component doesn't work with SSR because it uses `window.navigator` internally.
 */
export const DynamicLocalizedPricingLabelNoSSR =
  dynamic<LocalizedPricingLabelProps>(
    () =>
      import('./LocalizedPricingLabel').then((mod) => {
        return mod.LocalizedPricingLabel
      }),
    {
      loading() {
        return <Skeleton animated className="h-[1.625rem] w-full lg:h-11" />
      },
      ssr: false,
    },
  )
