import { useEffect } from 'react'

import { useLatestRef } from '$/modules/useLatestRef'

type Args = {
  ref: React.RefObject<HTMLElement>
  callback: (e: MouseEvent) => void
  /**
   * If `true` it will not call the callback
   */
  disabled?: boolean
}

export function useClickOutside(args: Args) {
  const { ref, callback, disabled } = args

  const handleClickRef = useLatestRef((e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback(e)
    }
  })

  useEffect(
    function addListenerToDocument() {
      const handleClick = handleClickRef.current
      let reqAnimationFrameId: number | undefined

      if (!disabled) {
        reqAnimationFrameId = requestAnimationFrame(() => {
          document.addEventListener('click', handleClick)
          reqAnimationFrameId = undefined
        })
      }

      return () => {
        document.removeEventListener('click', handleClick)

        if (reqAnimationFrameId) {
          cancelAnimationFrame(reqAnimationFrameId)
          reqAnimationFrameId = undefined
        }
      }
    },
    [disabled, handleClickRef],
  )
}
