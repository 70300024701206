import { useEffect } from 'react'

interface RestoreStyle {
  value: string
  el: HTMLElement
  key: string | 'className'
}

function getScrollbarSize() {
  const scrollDiv = document.createElement('div')

  scrollDiv.style.width = '99px'
  scrollDiv.style.height = '99px'
  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'
  scrollDiv.style.overflow = 'scroll'

  document.body.appendChild(scrollDiv)
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth

  document.body.removeChild(scrollDiv)

  return scrollbarSize
}

function getPaddingRight(node: HTMLElement) {
  return parseInt(window.getComputedStyle(node).paddingRight, 10) || 0
}

function isOverflowing(container: HTMLElement) {
  const doc = window.document

  if (doc.body === container) {
    return window.innerWidth > doc.documentElement.clientWidth
  }

  return container.scrollHeight > container.clientHeight
}

function handleContainer(container: HTMLElement) {
  const restoreStyle: RestoreStyle[] = []

  if (isOverflowing(container)) {
    const scrollbarSize = getScrollbarSize()

    restoreStyle.push({
      value: container.style.paddingRight ?? '',
      key: 'padding-right',
      el: container,
    })

    container.style.paddingRight = `${
      getPaddingRight(container) + scrollbarSize
    }px`
  }

  // https://css-tricks.com/snippets/css/force-vertical-scrollbar/
  const parent = container.parentElement
  const scrollContainer =
    parent?.nodeName === 'HTML' &&
    window.getComputedStyle(parent).overflowY === 'scroll'
      ? parent
      : container

  restoreStyle.push({
    value: 'overflow-hidden',
    key: 'className',
    el: scrollContainer,
  })

  scrollContainer.classList.add('overflow-hidden')

  const restore = () => {
    restoreStyle.forEach(({ value, el, key }) => {
      if (key === 'className') {
        el.classList.remove('overflow-hidden')
      } else if (value) {
        el.style.setProperty(key, value)
      } else {
        el.style.removeProperty(key)
      }
    })
  }

  return restore
}

export function useBodyScrollLock(lock: boolean) {
  useEffect(
    function calculateBodyPadding() {
      // the first call locks the window body and returns a function to restore it
      // the second call executes the closure and restores the window body
      return lock
        ? handleContainer(document.body)
        : handleContainer(document.body)()
    },
    [lock],
  )
}
