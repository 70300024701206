/**
 * Calculate the offset between the element and the top of the page
 */
export function accumulateOffsetTop(el: HTMLElement | null, extraOffset = 0) {
  let totalOffset = extraOffset

  while (el) {
    totalOffset += el.offsetTop - el.scrollTop + el.clientTop
    el = el.offsetParent as HTMLElement | null
  }

  return totalOffset
}
