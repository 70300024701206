import type { SVGComponent } from '../types'

export const ArrowUpRight: SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="1.75"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="17" y1="7" x2="7" y2="17" />
    <polyline points="8 7 17 7 17 16" />
  </svg>
)
