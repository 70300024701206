'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './RouteStepper'

const Component = lazy(() => import('./RouteStepper'))

export const RouteStepper: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return (
    <Suspense key={props.dataSliceType} fallback={rendered}>
      {rendered}
    </Suspense>
  )
}
