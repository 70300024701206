'use client'

import { useState, useEffect } from 'react'

import { throttleFn } from '../throttleFn'

import type { RefObject } from 'react'

interface ScrollUtilities {
  hasScrolled: boolean
  hasReachedEndOfScroll: boolean
}

type Direction = 'vertical' | 'horizontal'

type Args = {
  ref?: RefObject<HTMLElement>
  /**
   * @default 'vertical'
   */
  direction?: Direction
  /**
   * If the hook should not add the scroll callback.
   * This is useful when we want to add/remove the
   * callback conditionally.
   */
  turnOff?: boolean
}

export function useScrollUtilities(args?: Args): ScrollUtilities {
  const { ref, direction = 'vertical' } = args ?? {}

  const [hasScrolled, setHasScrolled] = useState(false)
  const [hasReachedEndOfScroll, setHasReachedEndOfScroll] = useState(false)

  useEffect(
    function handleScrollEffect() {
      const handleScroll = throttleFn(() => {
        if (ref?.current) {
          const element = ref.current

          if (direction === 'vertical') {
            setHasScrolled(element.scrollTop > 0)
            setHasReachedEndOfScroll(
              element.scrollTop >= element.scrollHeight - element.clientHeight,
            )
          }

          if (direction === 'horizontal') {
            setHasScrolled(element.scrollLeft > 0)
            setHasReachedEndOfScroll(
              element.scrollLeft >= element.scrollWidth - element.clientWidth,
            )
          }
        } else {
          if (direction === 'vertical') {
            setHasScrolled(window.scrollY > 0)
          }

          if (direction === 'horizontal') {
            setHasScrolled(window.scrollX > 0)
          }
        }
      }, 100)

      const containerRef = ref?.current

      if (ref?.current) {
        ref.current.addEventListener('scroll', handleScroll, { passive: true })
      } else {
        window.addEventListener('scroll', handleScroll, { passive: true })
      }

      return () => {
        if (containerRef) {
          containerRef.removeEventListener('scroll', handleScroll)
        } else {
          window.removeEventListener('scroll', handleScroll)
        }
      }
    },
    [ref, direction],
  )

  return { hasScrolled, hasReachedEndOfScroll }
}
