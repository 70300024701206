import { LinkType, isFilled } from '@prismicio/client'

import type { CustomType } from '$/models/prismicTypes'

import type {
  LinkField,
  EmptyLinkField,
  FilledLinkToMediaField,
  FilledLinkToWebField,
  FilledContentRelationshipField,
} from '@prismicio/client'

type ArgLinkField =
  | LinkField
  | EmptyLinkField
  | FilledContentRelationshipField
  | null
  | undefined

export function isPrismicLinkFilled(
  linkField: ArgLinkField,
): linkField is FilledLink {
  if (linkField == null) return false

  return Boolean(
    isFilled.link(linkField) &&
      (('url' in linkField && linkField.url) ||
        ('id' in linkField && linkField.id)),
  )
}

export function isMediaLinkFilled(
  linkField: ArgLinkField,
): linkField is FilledLinkToMediaField {
  return Boolean(
    isFilled.link(linkField) &&
      'url' in linkField &&
      linkField.url &&
      linkField.link_type === LinkType.Media,
  )
}

export function isPrismicLinkToDocumentFilled<
  TypeEnum extends CustomType = CustomType,
>(
  linkField: ArgLinkField,
  docType?: TypeEnum,
): linkField is FilledContentRelationshipField<TypeEnum> {
  if (linkField == null) return false

  return Boolean(
    isFilled.link(linkField) &&
      'id' in linkField &&
      linkField.id &&
      ((docType &&
        /*
         * We have to make this cast because having an `id` inside a
         * link field is not exclusive to the `FilledContentRelationshipField`
         * type. But if the type is the same as `docType`, then we can be
         * sure that it is a `FilledContentRelationshipField`.
         */
        (linkField as FilledContentRelationshipField).type === docType) ||
        !docType),
  )
}

export type FilledLink =
  | FilledContentRelationshipField
  | FilledLinkToWebField
  | FilledLinkToMediaField
