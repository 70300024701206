'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './SecondaryBlogHeader'

const Component = lazy(() => import('./SecondaryBlogHeader'))

export const SecondaryBlogHeader: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return <Suspense fallback={rendered}>{rendered}</Suspense>
}
