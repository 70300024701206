'use client'

import clsx from 'clsx'

import type { CustomerStoriesResolvedSlice } from '$/pageBuilder/resolvers/customerStories'

import './styles.css'
import { useCustomerStoriesSliderContext } from './CustomerStoriesSliderContext'

type Props = {
  items: CustomerStoriesResolvedSlice['items']
}

export function SlideTitles(props: Props) {
  const { items } = props
  const {
    inView,
    currentSlide,
    setCurrentSlide,
    slideChangeDurationMs,
    userImperativelySetCurrentSlide,
  } = useCustomerStoriesSliderContext()

  return (
    <ul className="max-w-md lg:pt-10 xl:pt-0">
      {items.map((item, i) => {
        const highlighted = inView && currentSlide === i

        return (
          <li key={i}>
            <button
              type="button"
              onClick={() => setCurrentSlide(i)}
              className="relative cursor-pointer outline-none"
            >
              <hr className="absolute left-0 top-0 h-full w-1 bg-gray-200" />
              <div className="absolute flex h-full w-1">
                {highlighted && (
                  <hr
                    className={clsx(
                      'absolute left-0 top-0 h-full max-h-full w-1 bg-circuitBlue-600',
                      {
                        'customer-stories__slider-highlight-indicator--grow':
                          !userImperativelySetCurrentSlide,
                        'customer-stories__slider-highlight-indicator--show opacity-0':
                          userImperativelySetCurrentSlide,
                      },
                    )}
                    style={
                      {
                        '--highlight-grow-duration': `${slideChangeDurationMs}ms`,
                      } as React.CSSProperties
                    }
                  />
                )}
              </div>
              <div
                className={clsx(
                  'px-4 py-2 duration-300 motion-safe:transition-colors lg:px-6 lg:py-3',
                  {
                    'text-body-website text-gray-600': currentSlide !== i,
                    'text-button-cta-website text-circuitBlue-600':
                      currentSlide === i,
                  },
                )}
              >
                {item.title}
              </div>
            </button>
          </li>
        )
      })}
    </ul>
  )
}
