'use client'

import clsx from 'clsx'

import { Button } from '$/components/base/Button'
import { ArrowVertical } from '$/components/Icons/ArrowVertical'

import { TRACKING_SECTION_INPUT_ID } from '../TrackingPackageHero/utils'

type ButtonProps = {
  className?: string
  onClick?: () => void
}

export function StaticBannerCTAButton(props: ButtonProps) {
  const { className, onClick } = props

  function handleClick() {
    const el = document.getElementById(TRACKING_SECTION_INPUT_ID)

    onClick?.()

    if (el?.focus) {
      el.focus()
      // firefox doesn't move the page in case of an offscreen focus, so we manually trigger a scroll to the element
      window.scrollTo({ top: el.clientHeight, behavior: 'smooth' })
    }
  }

  return (
    <Button
      size="medium"
      onClick={handleClick}
      endSlot={<ArrowVertical />}
      className={clsx('w-full', className)}
    >
      Track your package
    </Button>
  )
}
