import type { StandardImageField, EnhancedImageField } from './types'
import type {
  FilledImageFieldImage,
  EmptyImageFieldImage,
} from '@prismicio/client'

// Prismic Legacy URL ignores imgix parameters and needs to be converted to the new URL format
const LEGACY_IMAGES_BASE_URL = 'https://prismic-io.s3.amazonaws.com'

export const IMAGES_BASE_URL = 'https://images.prismic.io'
export const SVG_BASE_URL = 'https://getcircuit.cdn.prismic.io'

/**
 * Standardizes an image variant to a common format.
 *
 * @param image - The image variant to be standardized.
 * @param useRect - A boolean indicating whether to apply a rectangular shape to the image.
 * @returns The standardized image field, desktop values taking precedence.
 *
 * @example
 * const imageVariant = {
 *   desktop: {
 *     url: 'https://example.com/image.jpg',
 *     alt: 'Example Image',
 *     dimensions: {
 *       width: 800,
 *       height: 600
 *     }
 *   },
 *   mobile: {
 *     url: 'https://example.com/image-mobile.jpg',
 *     alt: 'Example Mobile Image',
 *     dimensions: {
 *       width: 400,
 *       height: 300
 *     }
 *   }
 * };
 *
 * or
 *
 * const imageVariant = {
 *   url: 'https://example.com/image.jpg',
 *   alt: 'Example Image',
 *   dimensions: {
 *     width: 800,
 *     height: 600
 *   },
 *   mobile: {
 *     url: 'https://example.com/image-mobile.jpg',
 *     alt: 'Example Mobile Image',
 *     dimensions: {
 *       width: 400,
 *       height: 300
 *     }
 *   }
 * };
 *
 * const useRect = false;
 *
 * const standardizedImage = standardizeImage(imageVariant, useRect);
 * console.log(standardizedImage);
 *
 * // Output:
 * // {
 * //   url: 'https://example.com/image.jpg',
 * //   alt: 'Example Image',
 * //   dimensions: {
 * //     width: 800,
 * //     height: 600
 * //   }
 * // }
 */
export function standardizeImage(
  image: EnhancedImageField,
  useRect: boolean,
): StandardImageField | null {
  let desktop: FilledImageFieldImage | EmptyImageFieldImage | undefined
  let mobile: FilledImageFieldImage | EmptyImageFieldImage | undefined

  if ('desktop' in image) {
    image
    desktop = image.desktop
    mobile = image.mobile
  } else {
    const { mobile: legacyMobile, ...legacyDesktop } = image

    desktop = legacyDesktop
    mobile = legacyMobile

    desktop.url = legacyDesktop.url?.replace(
      LEGACY_IMAGES_BASE_URL,
      IMAGES_BASE_URL,
    )

    if (mobile) {
      mobile.url = legacyMobile?.url?.replace(
        LEGACY_IMAGES_BASE_URL,
        IMAGES_BASE_URL,
      )
    }
  }

  let validURL

  if (desktop?.url) {
    validURL = desktop.url
  } else if (mobile?.url) {
    validURL = mobile.url
  } else {
    // Escape if there's not valid URL
    return null
  }

  return {
    url: applyRect(validURL, useRect),
    alt: desktop?.alt ?? mobile?.alt ?? '',
    dimensions: desktop?.dimensions ??
      mobile?.dimensions ?? { width: undefined, height: undefined },
  }
}

function applyRect(url: string, useRect: boolean): string {
  const formatURL = new URL(url)
  const params = formatURL.searchParams

  const rect = params.get('rect')

  if (rect && useRect) {
    params.set('rect', rect)
  } else {
    params.delete('rect')
  }

  return formatURL.href
}
