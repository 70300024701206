'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './Newsletter'

const Component = lazy(() => import('./Newsletter'))

export const Newsletter: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return (
    <Suspense key={props.dataSliceType} fallback={rendered}>
      {rendered}
    </Suspense>
  )
}
