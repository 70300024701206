'use client'

import {
  useMemo,
  useContext,
  createContext,
  type PropsWithChildren,
} from 'react'

import { useToc } from './useToc'

import type { HeadingInfo } from './types'

type State = {
  activeHeading: number
  headings: HeadingInfo[]
}

const DEFAULT_STATE: State = {
  activeHeading: 0,
  headings: [],
}

const TocContext = createContext<State>(DEFAULT_STATE)

type Props = {
  initialHeadingsInfo?: HeadingInfo[]
}

export function TocProvider(props: PropsWithChildren<Props>) {
  const { children, initialHeadingsInfo } = props

  const { activeHeading, headings } = useToc({
    initialHeadingsInfo,
  })

  const value: State = useMemo(
    () => ({
      headings,
      activeHeading,
    }),
    [activeHeading, headings],
  )

  return <TocContext.Provider value={value}>{children}</TocContext.Provider>
}

export function useTocContext() {
  const ctx = useContext(TocContext)

  if (!ctx) {
    throw new Error("`useTocContext` doesn't have a provider")
  }

  return ctx
}
