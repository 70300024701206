'use client'

import clsx from 'clsx'
import { Suspense, lazy, useRef } from 'react'
import { useInView } from 'framer-motion'

import { isMediaLinkFilled } from '$/modules/isPrismicLinkFilled'
import type { CircuitProductCardResolvedSlice } from '$/pageBuilder/resolvers/circuitProductCard'

type Props = {
  index: number
  variation: CircuitProductCardResolvedSlice['variation']
  animation: CircuitProductCardResolvedSlice['items'][0]
}

const CircuitProductRiveAnimation = lazy(
  () => import('./CircuitProductRiveAnimation'),
)

export function CircuitProductRiveAnimationItem(props: Props) {
  const { animation, variation } = props

  const containerRef = useRef<HTMLLIElement>(null)

  const inView = useInView(containerRef, { once: true })

  let renderedMedia: React.ReactNode = null

  if (isMediaLinkFilled(animation.riveAnimation)) {
    renderedMedia = inView ? (
      <Suspense>
        <CircuitProductRiveAnimation
          link={animation.link}
          animation={animation.riveAnimation}
        />
      </Suspense>
    ) : null
  }

  return (
    <li
      ref={containerRef}
      className={clsx(
        'relative overflow-hidden rounded-lg bg-gray-50 p-4 sm:p-6',
        'text-uppercase-website text-gray-500',
        {
          'sm:aspect-[3/2]': variation !== 'cpt',
          'sm:aspect-[5/2]': variation === 'cpt',
          'hidden sm:block': !animation.showOnMobile,
          'h-40 w-full sm:h-auto sm:w-auto': animation.showOnMobile,
        },
      )}
    >
      {renderedMedia}
      <span className="relative hidden sm:block">{animation.title}</span>
    </li>
  )
}
