'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './PricingTable'

const Component = lazy(() => import('./PricingTable'))

export const PricingTable: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return (
    <Suspense fallback={rendered} key={props.dataSliceType}>
      {rendered}
    </Suspense>
  )
}
