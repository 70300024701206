import clsx from 'clsx'

import { AlertCircle } from '$/components/Icons/AlertCircle'

import type { InputProps } from './Input'

export const ErrorMessage = ({
  size,
  error,
  className,
}: Pick<InputProps, 'size' | 'error' | 'className'>) => {
  const topMarginMap = {
    small: 'mt-1',
    large: 'mt-2',
    'lg:large': 'mt-1 lg:mt-2',
  }

  // in case we just need the error as a prop for the input border
  if (error == null) return null
  if (typeof error === 'boolean') return null
  if (typeof error === 'string' && error.trim() === '') return null

  return (
    <div
      className={clsx(
        className,
        'text-body-small-website flex items-center space-x-1 text-red-500',
        topMarginMap[size],
      )}
    >
      <AlertCircle className="mt-1 h-4 w-4 self-start" />
      <span>{error}</span>
    </div>
  )
}
