'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './PricingSummary'

const Component = lazy(() => import('./PricingSummary'))

export const PricingSummary: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return (
    <Suspense fallback={rendered} key={props.dataSliceType}>
      {rendered}
    </Suspense>
  )
}
