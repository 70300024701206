'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './CardWithVideo'

const Component = lazy(() => import('./CardWithVideo'))

export const CardWithVideo: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return <Suspense fallback={rendered}>{rendered}</Suspense>
}
