import clsx from 'clsx'

import type { ComponentProps } from 'react'

type ArrowVerticalProps = {
  className?: string
  orientation?: 'up' | 'down'
} & ComponentProps<'svg'>

export const ArrowVertical = (props: ArrowVerticalProps) => {
  const { className: css, orientation = 'up', ...rest } = props

  return (
    <svg
      className={clsx([
        css,
        [orientation === 'down' && '-rotate-180'],
        'transform transition',
      ])}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="1.75"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="18" y1="11" x2="12" y2="5" />
      <line x1="6" y1="11" x2="12" y2="5" />
    </svg>
  )
}
