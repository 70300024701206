'use client'

import { forwardRef, useEffect, useRef } from 'react'
import clsx from 'clsx'

import { useForkRef } from '$/modules/useForkRef'

import { ErrorMessage } from './ErrorMessage'

import type { ReactNode, InputHTMLAttributes } from 'react'

export type InputProps = {
  startSlot?: ReactNode
  endSlot?: ReactNode
  error?: ReactNode
  size: 'small' | 'large' | 'lg:large'
  variation: 'text' | 'number'
  label?: string
  classes?: Partial<CompleteClasses>
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>

type CompleteClasses = {
  container: string
  inputWrapper: string
  input: string
  label: string
  errorContainer: string
}

const commonClasses = `
  ease-in
  duration-100
  focus-within:ring-[3px]
  relative
`

const defaultFocusWithinStyles = `
  focus-within:outline-none
  focus-within:ring-circuitBlue-500/20
  focus-within:border-transparent
  hover:border-circuitBlue-500
  hover:transition-colors
  transition-shadow
`

const sizesMap = {
  // 10px
  small: 'py-[0.625rem]',
  // 14px
  large: 'py-[0.875rem]',
  'lg:large': 'py-[0.625rem] lg:py-[0.875rem]',
}

const textVariations = {
  text: 'text-body-website',
  number: 'text-number-large-website',
}

const disabledStyles = 'border-gray-100 bg-gray-50 text-gray-500'

const errorStyles = `
  border-red-500
  focus-within:ring-red-500/20
`

const resetStyles =
  'border-none border-0 p-0 shadow-none outline-none focus:ring-transparent'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function Input(props, refProp) {
    const {
      error,
      startSlot,
      endSlot,
      size,
      disabled,
      className: classNameProp,
      label,
      variation = 'text',
      classes = {},
      autoFocus,
      ...rest
    } = props

    const innerRef = useRef<HTMLInputElement>(null)
    const ref = useForkRef(innerRef, refProp)

    useEffect(
      /**
       * Using native `autoFocus` may cause some strange behaviors,
       * like not animating the entrance of the element using
       * `Transition` from HeadlessUI.
       */
      function autoFocusInput() {
        if (innerRef.current && autoFocus) {
          innerRef.current.focus()
        }
      },
      [autoFocus],
    )

    return (
      <label className={clsx('flex flex-col', classes.container)}>
        {label && (
          <span
            className={clsx(
              classes.label,
              'text-body-small-bold-website mb-1 text-start text-gray-700',
            )}
          >
            {label}
          </span>
        )}
        <div
          className={clsx([
            commonClasses,
            sizesMap[size],
            classes.inputWrapper,
            textVariations[variation],
            disabled && disabledStyles,
            error && errorStyles,
            !disabled && !error && defaultFocusWithinStyles,
            'inline-flex max-w-full gap-x-2 rounded-lg border border-gray-100 bg-white',
          ])}
        >
          {startSlot && (
            <span className="absolute top-1/2 flex -translate-y-1/2 items-center pl-4 text-gray-400">
              {startSlot}
            </span>
          )}
          <input
            ref={ref}
            disabled={disabled}
            className={clsx([
              !startSlot && 'pl-4',
              !endSlot && 'pr-4',
              startSlot && 'pl-[42px]',
              classNameProp,
              resetStyles,
              classes.input,
              textVariations[variation],
              disabled && disabledStyles,
              'w-full placeholder:text-body-website placeholder:text-gray-300',
            ])}
            {...rest}
          />
          {endSlot && (
            <span className="flex items-center pr-4 text-gray-400">
              {endSlot}
            </span>
          )}
        </div>
        {error && (
          <ErrorMessage
            size={size}
            error={error}
            className={classes.errorContainer}
          />
        )}
      </label>
    )
  },
)
