'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './HeroWithImageAndVideo'

const Component = lazy(() => import('./HeroWithImageAndVideo'))

export const HeroWithImageAndVideo: typeof ComponentType = (props) => {
  return (
    <Suspense fallback={<Component {...props} />} key={props.dataSliceType}>
      <Component {...props} />
    </Suspense>
  )
}
