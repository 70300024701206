'use client'

import clsx from 'clsx'
import { useRef } from 'react'
import { Disclosure, Transition } from '@headlessui/react'

import type { MenuLinkStructure, MultiLinkMenu } from '$/models/navigation'
import { useScrollUtilities } from '$/modules/hooks/useScrollUtilities'

import { LinkWrapper } from '../LinkWrapper'
import { ChevronVertical } from '../Icons/ChevronVertical'

type MobileNavigationProps = {
  list: MultiLinkMenu[]
}

export function MobileNavigation({ list }: MobileNavigationProps) {
  const ref = useRef<HTMLElement>(null)

  const { hasScrolled } = useScrollUtilities({ ref })

  return (
    <nav className="overflow-y-auto md:h-full" ref={ref}>
      <dl className="divide-y divide-gray-200">
        {list.map((menuItem) => (
          <Disclosure as="div" key={menuItem.heading}>
            {({ open }) => {
              return (
                <SubDisclosure
                  open={open}
                  heading={menuItem.heading}
                  href={menuItem.href}
                  links={menuItem.links}
                  hasScrolled={hasScrolled}
                />
              )
            }}
          </Disclosure>
        ))}
      </dl>
    </nav>
  )
}

type SubDisclosureProps = {
  open: boolean
  heading: string
  href?: string
  links?: MenuLinkStructure[]
  hasScrolled: boolean
}

function SubDisclosure({
  href,
  heading,
  links,
  open,
  hasScrolled,
}: SubDisclosureProps) {
  if (links === undefined || links.length === 0) {
    return (
      <LinkWrapper href={href}>
        <Disclosure.Panel
          as="dd"
          static
          className="flex w-full items-start justify-between px-6 py-4 text-left"
        >
          <p className="text-button-cta-website text-gray-800">{heading}</p>
        </Disclosure.Panel>
      </LinkWrapper>
    )
  }

  return (
    <>
      <Disclosure.Button
        className={clsx([
          'sticky top-0 z-10 flex w-full items-center justify-between bg-white px-6 py-4 text-left',
          open && hasScrolled && 'shadow-base',
        ])}
      >
        <span className="text-button-cta-website text-gray-800">{heading}</span>
        <span className="ml-6 flex items-center text-gray-600">
          <ChevronVertical
            aria-hidden="true"
            position={open ? 'up' : 'down'}
            className="transform transition"
            width={20}
            height={20}
          />
        </span>
      </Disclosure.Button>
      <Transition
        show={open}
        unmount={false}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="bg-gradient-to-b from-white to-gray-10 pb-4"
      >
        {links.map((link, index) => (
          <Disclosure.Panel as="dd" key={link.heading}>
            <LinkWrapper
              className="block rounded-lg px-6 py-2"
              href={link.href}
              target={link.target}
              rel={link.rel}
            >
              <div className="ml-2 flex">
                {link.icon && (
                  <div dangerouslySetInnerHTML={{ __html: link.icon }} />
                )}
                <div className="ml-2 mr-auto flex flex-col">
                  <p
                    className={clsx([
                      'text-body-small-bold-website',
                      link.bottomSticky && index === links.length - 1
                        ? 'text-circuitBlue-600'
                        : 'text-gray-900',
                    ])}
                  >
                    {link.heading}
                  </p>
                  <p className="text-body-small-website text-gray-600">
                    {link.description}
                  </p>
                </div>
                <span className=" h-fit rounded-lg bg-circuitBlue-50 px-2 text-circuitBlue-500 group-hover:bg-white">
                  {link.badge}
                </span>
              </div>
            </LinkWrapper>
          </Disclosure.Panel>
        ))}
      </Transition>
    </>
  )
}
