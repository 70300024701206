import clsx from 'clsx'

import './styles.css'

type Props = {
  className?: string
  animated?: boolean
}

/**
 * This component is based on `react-loading-skeleton`,
 * @see https://www.npmjs.com/package/react-loading-skeleton
 */
export function Skeleton({ className, animated }: Props) {
  return (
    <span
      className={clsx('skeleton', className, animated && 'skeleton-animation')}
    >
      &zwnj;
    </span>
  )
}
