'use client'

/**
 * We need this file because `radix-ui` doesn't
 * have an `'use client'` in their code, so if
 * we import it in a component, we would have to
 * make the component a client component. This
 * way, we can keep the component a server
 * component and use the dependency without any
 * errors.
 */
export { Scrollbar, Thumb, Root, Viewport } from '@radix-ui/react-scroll-area'
