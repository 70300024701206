type ImageLoader = {
  src: string
  width: number
  quality?: number
}

function prismicImageLoader({ src, width, quality }: ImageLoader) {
  const url = new URL(`${src}`)
  const params = url.searchParams

  params.set('auto', 'compress,format')
  params.set('fit', 'max')
  params.set('w', width.toString())
  // Some primis images include height in the URL, this clashes with the responsive image approach
  params.delete('h')
  params.set('q', (quality ?? 75).toString())

  return url.href
}

export { prismicImageLoader }
