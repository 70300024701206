'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './HeroScreenshots'

const Component = lazy(() => import('./HeroScreenshots'))

export const HeroScreenshots: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return <Suspense fallback={rendered}>{rendered}</Suspense>
}
