import { useRef } from 'react'

// https://epicreact.dev/the-latest-ref-pattern-in-react/
export function useLatestRef<T>(value: T) {
  const ref = useRef<T>(value)

  ref.current = value

  return ref as {
    readonly current: T
  }
}
