'use client'

import clsx from 'clsx'

import { LinkWrapper } from '$/components/LinkWrapper'
import { ResponsiveImage } from '$/components/ResponsiveImage'
import { ArrowUpRight } from '$/components/Icons/ArrowUpRight'
import type { CustomerStoriesResolvedSlice } from '$/pageBuilder/resolvers/customerStories'

import { useCustomerStoriesSliderContext } from './CustomerStoriesSliderContext'

type Props = {
  slides: CustomerStoriesResolvedSlice['items']
}

export function CustomerStoriesSlider(props: Props) {
  const { slides } = props

  const {
    currentSlide,
    onItemClick,
    showReadArticle,
    onMouseEnterInSlide,
    slideTrackerProps: { ref: slideTrackerRef, ...slideTrackerProps },
  } = useCustomerStoriesSliderContext()

  const [firstSlide] = slides

  return (
    <div className="relative w-full overflow-visible py-1">
      {/* Since we positioned the slider track with `position: absolute` we need a
      fake slide to force the browser to keep the height of the slider correct.
      If we don't do this, the height will be 0 by default. */}
      <div className="pointer-events-none invisible min-w-max">
        <ResponsiveImage
          useRect
          prismicImage={firstSlide.image}
          className="h-96 w-auto lg:h-[576px]"
          sizes={{
            default: '300px',
            lg: '448px',
          }}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <ul
        ref={slideTrackerRef as React.Ref<HTMLUListElement>}
        {...slideTrackerProps}
        className={clsx(
          // `min-w-max` needed because of Safari.
          'absolute bottom-0 left-0 top-0 min-w-max',
          'flex items-start transition-transform duration-500 xl:bottom-1 xl:top-1',
        )}
      >
        {slides.map(({ image, description, link }, i) => {
          return (
            <li key={i} className="relative min-w-max">
              <div className="pr-4 lg:pr-6">
                <ResponsiveImage
                  useRect
                  prismicImage={image}
                  sizes={{
                    default: '300px',
                    lg: '448px',
                  }}
                  className={clsx('h-96 w-auto rounded-2xl lg:h-[576px]', {
                    /**
                     * If the slide is hidden, we remove
                     * the shadow so it doesn't show
                     * behind the text on the left side
                     * of the slice.
                     */
                    'shadow-base': currentSlide <= i,
                  })}
                />
                <div className="absolute inset-0 pr-4 lg:pr-6">
                  <LinkWrapper
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={onItemClick}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx(
                      'customer-stories_slide flex h-full w-full items-end',
                      'group rounded-2xl p-4 lg:p-8',
                    )}
                  >
                    <div className="overflow-y-hidden text-gray-10">
                      <div
                        onMouseEnter={onMouseEnterInSlide}
                        className={clsx('transform-gpu transition-transform', {
                          'translate-y-0':
                            currentSlide === i && showReadArticle,
                          'translate-y-10 group-hover:translate-y-0': !(
                            currentSlide === i && showReadArticle
                          ),
                        })}
                      >
                        <p className="text-subheading-website mb-4">
                          {description}
                        </p>
                        <div className="text-button-cta-website flex gap-1">
                          <span>Read article</span>
                          <span>
                            <ArrowUpRight />
                          </span>
                        </div>
                      </div>
                    </div>
                  </LinkWrapper>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
