'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './FooterNewsletterForm'

const Component = lazy(() => import('./FooterNewsletterForm'))

export const FooterNewsletterForm: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return <Suspense fallback={rendered}>{rendered}</Suspense>
}
