'use client'

// Next custom loader requires using Client Components to serialize the provided function.
// https://nextjs.org/docs/app/api-reference/components/image#loader

import Image from 'next/image'

import { prismicImageLoader } from './prismicImageLoader'
import { standardizeImage } from './utils'

import type {
  EnhancedImageField,
  Breakpoint,
  SizeCssUnit,
  Sizes,
  StandardImageField,
} from './types'

type BaseImageProps = {
  className?: string
  /**
   * When true, the image will be considerer high priority and preload.
   * Lazy loading is automatically disabled for images using priority.
   * Images with priority = false will be lazy loaded.
   * @see {@link https://nextjs.org/docs/pages/api-reference/components/image#priority}
   *
   * @default false
   *
   */
  priority?: boolean
  style?: React.CSSProperties
  /**
   * If it should use the query string `rect` to request the image.
   *
   * @see {@link https://docs.imgix.com/apis/rendering/size/rect}
   * @default false
   */
  useRect?: boolean
  onLoad?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >['onLoad']

  /**
   * Creates a sizes string for the image element.
   * It follows Tailwind breakpoints and mobile-first approach.
   * The image **width** should be provided for each breakpoint. Allowed units are 'px', 'em' and 'vw'.
   * If no sizes are provided, next/image will default to 1x and 2x srcSets and no optimization and console.warn in development.
   *
   * @see {@link https://nextjs.org/docs/pages/api-reference/components/image#sizes}
   *
   * @default undefined
   *
   * @example
   * const sizes = {
   *   default: '100vw',
   *   md: '744px',
   *   lg: '805px'
   * } => "(min-width: 768px) 744px, (min-width: 1024px) 805px, 100vw"
   *
   */
  sizes?: Sizes
}

type WithPrismicImage = BaseImageProps & {
  /**
   * The Prismic image to be displayed.
   * It will be automatically standarized to asimple object, **desktop values taking precedence**.
   * If also provided, standardImage will be ignored.
   * **Either prismicImage or standardImage must be provided**.
   * If no valid image is provided, the component will return null.
   *
   * @example
   * const imageVariant = {
   *   desktop: {
   *     url: 'https://example.com/image.jpg',
   *     alt: 'Example Image',
   *     dimensions: {
   *       width: 800,
   *       height: 600
   *     }
   *   },
   *   mobile: {
   *     url: 'https://example.com/image-mobile.jpg',
   *     alt: 'Example Mobile Image',
   *     dimensions: {
   *       width: 400,
   *       height: 300
   *     }
   *   }
   * };
   *
   * or
   *
   * const imageVariant = {
   *   url: 'https://example.com/image.jpg',
   *   alt: 'Example Image',
   *   dimensions: {
   *     width: 800,
   *     height: 600
   *   },
   *   mobile: {
   *     url: 'https://example.com/image-mobile.jpg',
   *     alt: 'Example Mobile Image',
   *     dimensions: {
   *       width: 400,
   *       height: 300
   *     }
   *   }
   * };
   * <ResponsiveImage image={imageVariant} />
   */
  prismicImage: EnhancedImageField
  standardImage?: never
}

type WithStandardImage = BaseImageProps & {
  /**
   * The standarized image to be displayed.
   * prismicImage field takes precedence over this field.
   * This field will only be used if prismicImage is not provided.
   * **Either prismicImage or standardImage must be provided**.
   *
   * If a new host is provided, needs to be added to the next.config.js file.
   * @see {@link https://nextjs.org/docs/api-reference/next/image#remotepatterns}
   * @see next.config.js
   *
   * @example
   * const standardImage = {
   *  url: 'https://example.com/image.jpg',
   *  alt: 'Example Image',
   *  dimensions: {
   *   width: 800,
   *   height: 600
   *  },
   * };
   * <ResponsiveImage standardImage={standardImage} />
   */
  standardImage: StandardImageField
  prismicImage?: never
}

// Types could be easily extended/change to support any image source in the future
type ResponsiveImageProps = WithPrismicImage | WithStandardImage

function ResponsiveImage({
  prismicImage,
  sizes: originalSizes,
  style,
  onLoad,
  className,
  useRect = false,
  priority = false,
  standardImage,
}: ResponsiveImageProps): JSX.Element | null {
  // Escape if no image is provided
  if (!prismicImage && !standardImage) return null

  const image = prismicImage
    ? standardizeImage(prismicImage, useRect)
    : standardImage

  // Escape if image url is invalid
  if (!image) return null

  const sizes = originalSizes && createSizesString(originalSizes)
  const isSvg = image.url.endsWith('.svg')

  if (process.env.NODE_ENV === 'development' && !originalSizes && !isSvg) {
    console.warn(
      `No sizes were provided to ResponsiveImage for the URL: ${image.url}. Image srcset will not be properly optimized.`,
    )
  }

  return (
    <Image
      src={image.url}
      // Only Prismic images have a specific loader, all other images use the default loader
      loader={prismicImage && prismicImageLoader}
      // When svgs are used as images they shouldn't be optimized
      unoptimized={isSvg}
      priority={priority}
      alt={image.alt}
      width={image.dimensions.width}
      height={image.dimensions.height}
      sizes={sizes}
      className={className}
      style={style}
      onLoad={onLoad}
    />
  )
}

export { ResponsiveImage }

function createBreakpointValue(
  breakpoint: Breakpoint,
  value?: SizeCssUnit,
): `(min-width: ${number}px) ${SizeCssUnit}` | undefined {
  if (!value) return undefined

  const breakpointToValue = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  }

  return `(min-width: ${breakpointToValue[breakpoint]}px) ${value}`
}

function createSizesString(sizes: Sizes): string {
  type BreakpointOrDefault = Breakpoint | 'default'
  const sortedBreakpoints: BreakpointOrDefault[] = [
    '2xl',
    'xl',
    'lg',
    'md',
    'sm',
    'default',
  ]

  return sortedBreakpoints
    .map((breakpoint) =>
      breakpoint !== 'default'
        ? createBreakpointValue(breakpoint, sizes[breakpoint])
        : sizes.default,
    )
    .filter((value) => value !== undefined)
    .join(', ')
}
