'use client'

import dynamic from 'next/dynamic'

// we import both using dynamic to avoid SSR issues
// https://www.freecodecamp.org/news/how-to-bypass-es-modules-error-in-next-js/
export const CommuteCostQuiz = dynamic(
  () => import('./CommuteCostQuiz').then((e) => e.CommuteCostQuiz),
  {
    ssr: false,
  },
)
