import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import { isRoutePlannerRoute } from '$/modules/pathname'
import { useClickOutside } from '$/modules/useClickOutside'

import { Menu } from '../Icons/Menu'
import { XIcon } from '../Icons/XIcon'
import { Button } from '../base/Button'
import { LinkWrapper } from '../LinkWrapper'
import { NavigationMenu } from './NavigationMenu'
import { MobileNavigation } from './MobileNavigation'
import { TalkToSalesButton } from './TalkToSalesButton'

import type { HeaderRowProps } from './HeaderRow'

type GeneralHeaderProps = {
  stickyButtonData?: NonNullable<HeaderRowProps['primaryNavCTAs']>[0]
  CTAList?: HeaderRowProps['primaryNavCTAs']
  menuOpen: boolean
  pathname?: string
} & HeaderRowProps

type DesktopHeaderProps = {
  menuClickHandler: () => void
} & GeneralHeaderProps

export function DesktopHeader(
  props: React.PropsWithChildren<DesktopHeaderProps>,
) {
  const {
    logoLink,
    stickyButtonData,
    links,
    talkToSales,
    primaryNavCTAs,
    menuClickHandler,
    menuOpen,
    children,
    pathname,
  } = props

  return (
    <div className="w-full px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
      <div
        className={clsx(
          'header-height-animation header-container',
          'flex items-center justify-between py-4 lg:justify-start lg:space-x-10 lg:py-6',
        )}
      >
        <div className="flex justify-start gap-8 lg:w-0 lg:flex-1">
          <LinkWrapper
            {...logoLink}
            title="Product home page"
            className="group flex items-center"
          >
            {children}
          </LinkWrapper>
          <div className="hidden lg:flex lg:items-center">
            <NavigationMenu list={links} />
          </div>
        </div>
        <div className="-my-2 flex gap-3 lg:hidden">
          {stickyButtonData?.stickyText && (
            <Button
              rel={stickyButtonData.rel}
              href={stickyButtonData.href}
              target={stickyButtonData.target}
              size="medium"
              className="block lg:hidden"
            >
              {stickyButtonData.stickyText}
            </Button>
          )}
          <button
            aria-label="Menu"
            aria-expanded={menuOpen}
            onClick={menuClickHandler}
            className="flex items-center justify-center bg-white text-gray-600"
          >
            {menuOpen ? (
              <>
                <span className="sr-only">Close menu</span>
                <XIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
              </>
            ) : (
              <>
                <span className="sr-only">Open menu</span>
                <Menu className="h-5 w-5 text-gray-600" aria-hidden="true" />
              </>
            )}
          </button>
        </div>

        <div className="hidden items-center justify-end space-x-2 lg:flex lg:w-0 lg:flex-1">
          {talkToSales && <TalkToSalesButton />}
          {primaryNavCTAs?.map((cta, i) => {
            const { href, label, variation, pallet, rel, target } = cta

            return (
              <Button
                data-crp-event={
                  isRoutePlannerRoute(pathname) ? 'install-cta' : undefined
                }
                key={i}
                rel={rel}
                href={href}
                size="medium"
                pallet={pallet}
                target={target}
                variation={variation}
              >
                {label}
              </Button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type MobileHeaderProps = {
  onClose: () => void
} & Omit<GeneralHeaderProps, 'children'>

export function MobileHeader(props: MobileHeaderProps) {
  const { stickyButtonData, links, talkToSales, CTAList, onClose, menuOpen } =
    props

  const ref = useRef<HTMLDivElement>(null)

  useClickOutside({
    ref,
    callback: onClose,
    disabled: !menuOpen,
  })

  useEffect(
    function hideIntercomWhenOpened() {
      if (menuOpen) {
        window.Intercom?.('update', {
          hide_default_launcher: true,
        })
      } else {
        window.Intercom?.('update', {
          hide_default_launcher: false,
        })
      }
    },
    [menuOpen],
  )

  return (
    <div
      ref={ref}
      className="ml-auto flex h-[100dvh] w-full flex-col overflow-x-hidden bg-white shadow-lg lg:w-full"
    >
      <div className="mb-2 h-16 min-h-[4rem] md:h-20" />
      <MobileNavigation list={links} />
      <div className="mt-auto flex flex-col space-y-3 border-t border-gray-100 p-6 md:mt-0">
        {talkToSales && <TalkToSalesButton />}
        {CTAList?.map((cta, i) => {
          const { label, href, rel, target, pallet, variation } = cta

          return (
            <Button
              key={i}
              rel={rel}
              href={href}
              size="medium"
              target={target}
              pallet={pallet}
              variation={variation}
            >
              {label}
            </Button>
          )
        })}
        {stickyButtonData?.stickyText && (
          <Button
            size="medium"
            rel={stickyButtonData.rel}
            href={stickyButtonData.href}
            target={stickyButtonData.target}
            pallet={stickyButtonData.pallet}
            variation={stickyButtonData.variation}
          >
            {stickyButtonData.label}
          </Button>
        )}
      </div>
    </div>
  )
}
