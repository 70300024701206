'use client'

import clsx from 'clsx'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { useState } from 'react'

import type { PropsWithChildren } from 'react'
import type { TooltipProps } from './types'

import './styles.css'

const map = {
  content: {
    light: 'bg-white text-gray-700 drop-shadow-md',
    dark: 'bg-gray-900 text-white',
  },
  arrow: {
    light: 'text-white',
    dark: 'text-gray-900',
  },
}

const sizes = {
  small: 'px-3 py-2',
  large: 'px-3 py-3',
}

const arrowOffset = {
  small: -8,
  large: -10,
}

export function Tooltip(props: PropsWithChildren<TooltipProps>) {
  const {
    content,
    children,
    defaultOpen,
    open: openProp,
    onOpenChange,
    delayDuration,
    pallet,
    size,
    // trigger props
    triggerCss,
    // tooltip custom css
    contentCss,
    // tooltip content
    ...contentProps
  } = props

  const isUncontrolledTooltip = openProp === undefined

  // We need the tooltip to behave like a popover sometimes, so we add a few callbacks to control that interaction manually
  // https://github.com/radix-ui/primitives/issues/955#issuecomment-1144445691
  const [open, setOpen] = useState(defaultOpen ?? false)

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        open={isUncontrolledTooltip ? open : openProp}
        delayDuration={delayDuration ?? 100}
      >
        {isUncontrolledTooltip ? (
          <TooltipPrimitive.Trigger
            className={triggerCss}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onFocus={() => {
              /**
               * We need to delay setting open onFocus because on mobile this event fires before onClick.
               * Without the delay, the onClick event will close the Tooltip as soon as it is opened
               */
              setTimeout(() => setOpen(true), 0)
            }}
            onBlur={() => setOpen(false)}
            onClick={() => setOpen(!open)}
          >
            {children}
          </TooltipPrimitive.Trigger>
        ) : (
          <TooltipPrimitive.Trigger className={triggerCss}>
            {children}
          </TooltipPrimitive.Trigger>
        )}
        <TooltipPrimitive.Content
          className={clsx(
            'tooltip-content z-tooltip max-w-xs rounded-lg p-3',
            sizes[size],
            map.content[pallet],
            contentCss,
          )}
          alignOffset={arrowOffset[size]}
          sideOffset={4}
          {...contentProps}
        >
          {content}
          <TooltipPrimitive.Arrow
            asChild
            className={clsx('-translate-y-0.5 fill-current', map.arrow[pallet])}
          >
            <svg
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.0711 0.485289C14.962 0.485289 15.4081 1.56243 14.7782 2.1924L8.70711 8.26347C8.31658 8.654 7.68342 8.654 7.29289 8.26347L1.22183 2.1924C0.591867 1.56243 1.03803 0.485289 1.92894 0.485289L14.0711 0.485289Z"
                fill="currentColor"
              />
            </svg>
          </TooltipPrimitive.Arrow>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
