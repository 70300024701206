'use client'

import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react'
import { useInView } from 'framer-motion'

import type { DataSliceType } from '$/components/types'
import type { CustomerStoriesResolvedSlice } from '$/pageBuilder/resolvers/customerStories'

import { useDragCustomerStoriesSlider } from './useDragCustomerStoriesSlider'

type State = {
  inView: boolean
  currentSlide: number
  onItemClick: (e: React.MouseEvent) => void
  slideChangeDurationMs: number
  onMouseEnterInSlide: () => void
  setCurrentSlide: (newSlide: number) => void
  slideTrackerProps: ReturnType<
    typeof useDragCustomerStoriesSlider
  >['slideTrackerProps']
  /**
   * If it should show the "Read more" label
   * of the current slide below the slide
   * description. This label also shows on
   * hover, but we automatically show the
   * label to let the user know that the
   * slide is a link without having to hover
   * it.
   */
  showReadArticle: boolean
  /**
   * It tells if the user chose to display
   * a specific slide. If this happens, we
   * should stop the automatic slide
   * transition for a while.
   */
  userImperativelySetCurrentSlide: boolean
}

const DEFAULT_STATE: State = {
  inView: false,
  currentSlide: 0,
  showReadArticle: false,
  slideTrackerProps: {
    ref: { current: null },
    onMouseUp: () => {},
    onMouseDown: () => {},
    onMouseMove: () => {},
    onTouchEnd: () => {},
    onTouchStart: () => {},
    onTouchMove: () => {},
  },
  setCurrentSlide: () => {},
  slideChangeDurationMs: 6000,
  onMouseEnterInSlide: () => {},
  onItemClick: () => {},
  userImperativelySetCurrentSlide: false,
}

const CustomerStoriesSliderContext = createContext<State>(DEFAULT_STATE)

type Props = {
  totalSlides: number
  children: React.ReactNode
  dataSliceType: DataSliceType<CustomerStoriesResolvedSlice>
}

export function CustomerStoriesSliderContextProvider(props: Props) {
  const { children, dataSliceType, totalSlides } = props

  const intervalRef = useRef<number>()
  const timeoutRef = useRef<number>()
  const sectionRef = useRef<HTMLElement>(null)
  const [currentSlide, setCurrentSlide] = useState(DEFAULT_STATE.currentSlide)
  const [userImperativelySetCurrentSlide, setUserImperativelySetCurrentSlide] =
    useState(DEFAULT_STATE.userImperativelySetCurrentSlide)

  const inView = useInView(sectionRef, {
    once: true,
  })

  const { showReadArticle, onMouseEnterInSlide } = useShowReadArticle({
    currentSlide,
    inView,
  })

  const handleSetCurrentSlide = useCallback(
    (newSlide: number) => {
      setCurrentSlide(newSlide)
      setUserImperativelySetCurrentSlide(true)

      /*
       * If the user clicks in a slide we hide
       * the "Read article" label.
       */
      onMouseEnterInSlide()

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = undefined
      }

      timeoutRef.current = window.setTimeout(() => {
        timeoutRef.current = undefined
        setUserImperativelySetCurrentSlide(false)
        setCurrentSlide((oldSlide) => (oldSlide + 1) % totalSlides)

        /*
         * After twice the normal time, we set the
         * next slide and go back to setting the
         * interval to continue to change the slides
         * automatically.
         */
      }, DEFAULT_STATE.slideChangeDurationMs * 2)
    },
    [onMouseEnterInSlide, totalSlides],
  )

  const { onItemClick, userPressing, slideTrackerProps } =
    useDragCustomerStoriesSlider({
      currentSlide,
      totalSlides,
      onCurrentSlideChange: handleSetCurrentSlide,
      transitionClasses: 'transition-transform duration-500',
    })

  useEffect(
    function setIntervalToChangeSlide() {
      if (!inView || userImperativelySetCurrentSlide || userPressing) {
        return
      }

      intervalRef.current = window.setInterval(() => {
        setCurrentSlide((oldValue) => (oldValue + 1) % totalSlides)
      }, DEFAULT_STATE.slideChangeDurationMs)

      return () => {
        clearInterval(intervalRef.current)
        intervalRef.current = undefined
      }
    },
    [inView, totalSlides, userPressing, userImperativelySetCurrentSlide],
  )

  useEffect(function clearTimeoutOnUnmount() {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const state = useMemo<State>(
    () => ({
      inView,
      onItemClick,
      currentSlide,
      showReadArticle,
      slideTrackerProps,
      onMouseEnterInSlide,
      userImperativelySetCurrentSlide,
      setCurrentSlide: handleSetCurrentSlide,
      slideChangeDurationMs: DEFAULT_STATE.slideChangeDurationMs,
    }),
    [
      inView,
      onItemClick,
      currentSlide,
      showReadArticle,
      slideTrackerProps,
      onMouseEnterInSlide,
      handleSetCurrentSlide,
      userImperativelySetCurrentSlide,
    ],
  )

  return (
    <CustomerStoriesSliderContext.Provider value={state}>
      <section
        ref={sectionRef}
        className="relative"
        data-slice-type={dataSliceType}
      >
        {children}
      </section>
    </CustomerStoriesSliderContext.Provider>
  )
}

export function useCustomerStoriesSliderContext() {
  return useContext(CustomerStoriesSliderContext)
}

type UseShowReadArticleArgs = {
  currentSlide: number
  inView: boolean
}

const IN_TIMEOUT_DURATION_MS = 1500
const OUT_TIMEOUT_DURATION_MS = DEFAULT_STATE.slideChangeDurationMs - 4000

function useShowReadArticle(args: UseShowReadArticleArgs) {
  const { currentSlide, inView } = args

  const [showReadArticle, setShowReadArticle] = useState(false)
  const inTimeoutRef = useRef<number>()
  const outTimeoutRef = useRef<number>()

  /*
   * If the user hovers any slide we remove
   * don't have to automatically show the
   * "Read article" label.
   */
  const onMouseEnterInSlide = useCallback(() => {
    if (inTimeoutRef.current) {
      clearTimeout(inTimeoutRef.current)
      inTimeoutRef.current = undefined
    }

    if (outTimeoutRef.current) {
      clearTimeout(outTimeoutRef.current)
      outTimeoutRef.current = undefined
    }

    if (showReadArticle) {
      setShowReadArticle(false)
    }
  }, [showReadArticle])

  useEffect(
    function setTimeoutsOnSlideChange() {
      if (!inView) {
        return
      }

      clearTimeout(inTimeoutRef.current)

      inTimeoutRef.current = window.setTimeout(() => {
        setShowReadArticle(true)

        inTimeoutRef.current = undefined
        clearTimeout(outTimeoutRef.current)

        outTimeoutRef.current = window.setTimeout(() => {
          setShowReadArticle(false)

          outTimeoutRef.current = undefined
        }, OUT_TIMEOUT_DURATION_MS)

        return () => {
          clearTimeout(inTimeoutRef.current)
          clearTimeout(outTimeoutRef.current)

          inTimeoutRef.current = undefined
          outTimeoutRef.current = undefined
        }
      }, IN_TIMEOUT_DURATION_MS)
    },
    [inView, currentSlide],
  )

  return { showReadArticle, onMouseEnterInSlide }
}
