'use client'

import { useState, createContext } from 'react'

const DEFAULT_SELECTED_STOPS = 500

export const PricingContext = createContext({
  selectedStops: DEFAULT_SELECTED_STOPS,
  setSelectedStops: (stops: number) => {
    stops
  },
})

type Props = {
  children: React.ReactNode
}
const PricingProvider = ({ children }: Props) => {
  const [selectedStops, setSelectedStops] = useState(DEFAULT_SELECTED_STOPS)

  return (
    <PricingContext.Provider value={{ selectedStops, setSelectedStops }}>
      {children}
    </PricingContext.Provider>
  )
}

export default PricingProvider
