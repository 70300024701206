import {
  useRef,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from 'react'
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu'
import clsx from 'clsx'

import type { MenuLinkStructure, MultiLinkMenu } from '$/models/navigation'

import { LinkWrapper } from '../LinkWrapper'

import type { LinkProps } from '../types'

export function MenuItem(props: Omit<MenuLinkStructure, 'label'>) {
  const { rel, href, icon, badge, target, heading, description, bottomSticky } =
    props

  return (
    <li className={clsx('first:mt-1', !bottomSticky && 'last:mb-2')}>
      <NavigationMenuPrimitive.Link asChild>
        <LinkWrapper
          className={clsx([
            'group block',
            bottomSticky && 'mt-2 border-t border-gray-100 bg-gray-50 p-1',
            !bottomSticky && '-my-1 mx-1 rounded-lg p-3 hover:bg-gray-50',
          ])}
          href={href}
          rel={rel}
          target={target}
        >
          <div
            className={clsx([
              'flex',
              bottomSticky && 'rounded-b-lg px-3 py-4 hover:bg-white',
            ])}
          >
            {icon && <div dangerouslySetInnerHTML={{ __html: icon }} />}
            <div className="ml-2 mr-auto flex flex-col">
              <p
                className={clsx([
                  'text-body-small-bold-website text-gray-900',
                  bottomSticky && 'group-hover:text-circuitBlue-600',
                ])}
              >
                {heading}
              </p>
              <p className="text-body-small-website text-gray-600">
                {description}
              </p>
            </div>
            {badge && (
              <span className="h-fit rounded-lg bg-circuitBlue-50 px-2 py-1  text-circuitBlue-500 group-hover:bg-white">
                <p className="text-[0.875rem] font-medium leading-[0.875rem]">
                  {badge}
                </p>
              </span>
            )}
          </div>
        </LinkWrapper>
      </NavigationMenuPrimitive.Link>
    </li>
  )
}

type ItemProps = {
  heading: string
  href?: string
  links?: MenuLinkStructure[]
  setTrigger: Dispatch<SetStateAction<HTMLElement | undefined>>
  value: string
  activeTrigger: HTMLElement | undefined
} & Pick<LinkProps, 'rel' | 'target'>

function Item(props: ItemProps) {
  const {
    rel,
    href,
    links,
    value,
    target,
    heading,
    setTrigger,
    activeTrigger,
  } = props

  /**
   * We need the class `inline-block` because
   * `NavigationMenuPrimitive.Link` renders a `li` element. When
   * you have an anchor as the direct child of a `li` without
   * `display: inline-block`, something wrong happens with the
   * element's height. If you remove the class, you will see a difference.
   */
  const triggerClasses =
    'text-button-cta-website rounded-lg py-2 px-4 text-gray-800 hover:bg-gray-50 rounded-lg inline-block'

  if (!links?.length) {
    return (
      <NavigationMenuPrimitive.Item>
        <NavigationMenuPrimitive.Link asChild>
          <LinkWrapper
            rel={rel}
            target={target}
            href={href}
            className={triggerClasses}
          >
            {heading}
          </LinkWrapper>
        </NavigationMenuPrimitive.Link>
      </NavigationMenuPrimitive.Item>
    )
  }

  return (
    <NavigationMenuPrimitive.Item key={heading} value={heading}>
      <NavigationMenuPrimitive.Trigger
        onClick={(e) => e.preventDefault()}
        className={triggerClasses}
        ref={(node: SetStateAction<HTMLElement | undefined> | null) => {
          if (node == null) return
          if (heading === value && activeTrigger !== node) {
            setTrigger(node)
          }

          return node
        }}
      >
        {heading}
      </NavigationMenuPrimitive.Trigger>
      <NavigationMenuPrimitive.Content
        className="absolute left-0 top-0"
        asChild
      >
        <ul className="flex w-full flex-col pt-1">
          {links.map((link, index) => (
            <MenuItem
              key={`${link.heading}-${index}`}
              href={link.href}
              rel={link.rel}
              target={link.target}
              heading={link.heading}
              description={link.description}
              icon={link.icon}
              badge={link.badge}
              bottomSticky={link.bottomSticky && index === links.length - 1}
            />
          ))}
        </ul>
      </NavigationMenuPrimitive.Content>
    </NavigationMenuPrimitive.Item>
  )
}

type NavigationMenuProps = {
  list: MultiLinkMenu[]
}

export const NavigationMenu = ({ list }: NavigationMenuProps) => {
  const [offset, setOffset] = useState<number>(0)
  const listRef = useRef<HTMLUListElement>(null)
  const [value, setValue] = useState('')
  const [activeTrigger, setActiveTrigger] = useState<HTMLElement>()

  /**
   * radix-ui does not provide a way to get the active trigger element so we can position
   * the viewport element. This is a workaround for that.
   * https://github.com/radix-ui/primitives/issues/1462#issuecomment-1262157538
   */
  useEffect(
    function positionFloatingMenuBelowActiveElement() {
      const manualOffset = -28

      if (activeTrigger == null) return

      if (activeTrigger && listRef.current) {
        const triggerOffsetRight = activeTrigger.offsetLeft + manualOffset

        setOffset(Math.round(triggerOffsetRight))
      }

      if (value === '') {
        setOffset(0)
      }
    },
    [activeTrigger, value],
  )

  return (
    <NavigationMenuPrimitive.Root
      value={value}
      onValueChange={setValue}
      className="relative"
    >
      <NavigationMenuPrimitive.List
        className="flex items-center justify-center whitespace-nowrap bg-white"
        ref={listRef}
      >
        {list.map(({ heading, href, links, rel, target }) => (
          <Item
            key={heading}
            href={href}
            rel={rel}
            target={target}
            heading={heading}
            links={links}
            setTrigger={setActiveTrigger}
            value={value}
            activeTrigger={activeTrigger}
          />
        ))}
      </NavigationMenuPrimitive.List>

      <div
        className="menu-navigation-viewport-container absolute left-0 top-full flex justify-center"
        style={{
          transform: `translateY(${!offset ? -8 : 0}px)`,
        }}
      >
        <NavigationMenuPrimitive.Viewport
          forceMount
          className="menu-navigation-viewport"
          style={{
            // Avoid transitioning from initial position when first opening
            display: !offset ? 'none' : undefined,
            transform: `translateX(${offset}px)`,
            // 298px from figma specs
            width: '18.625rem',
          }}
        />
      </div>
    </NavigationMenuPrimitive.Root>
  )
}
