'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './HeroWithSubForEmail'

const Component = lazy(() => import('./HeroWithSubForEmail'))

export const HeroWithSubForEmail: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return (
    <Suspense key="hero-with-sub-for-email" fallback={rendered}>
      {rendered}
    </Suspense>
  )
}
