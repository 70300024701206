import clsx from 'clsx'

import type { ComponentProps } from 'react'

/**
 * TODO - We have ChevronVertical and ChevronHorizontal. This could be just Chevron
 */
type ChevronVerticalProps = {
  className?: string
  position?: 'up' | 'down' | 'right'
} & ComponentProps<'svg'>

export const ChevronVertical = (props: ChevronVerticalProps) => {
  const { className: css, position = 'up', ...rest } = props

  return (
    <svg
      className={clsx([
        css,
        [position === 'down' && 'rotate-180'],
        [position === 'right' && 'rotate-90'],
        'transform transition duration-300',
      ])}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="1.75"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="6 15 12 9 18 15" />
    </svg>
  )
}
