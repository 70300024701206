import { DIALOG_CFT_SALES } from '$/modules/dialogConfigs'

import { Button } from '../base/Button'
import { Headset } from '../Icons/Headset'

export function TalkToSalesButton() {
  return (
    <div className="px-2 lg:border-r">
      <Button
        href={DIALOG_CFT_SALES.href}
        fullWidth
        className="lg:w-auto"
        size="medium"
        pallet="gray"
        variation="borderless"
        startSlot={<Headset />}
      >
        Talk to sales
      </Button>
    </div>
  )
}
