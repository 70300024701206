'use client'

import { Suspense, lazy } from 'react'

import type ComponentType from './SplitVideo'

const Component = lazy(() => import('./SplitVideo'))

export const SplitVideo: typeof ComponentType = (props) => {
  const rendered = <Component {...props} />

  return (
    <Suspense fallback={rendered} key={props.dataSliceType}>
      {rendered}
    </Suspense>
  )
}
