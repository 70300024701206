import { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

import { accumulateOffsetTop } from '$/modules/accumulateOffsetTop'

import type { HeadingInfo } from './types'

// Implementation based on https://janosh.dev/blog/sticky-active-smooth-responsive-toc

const THROTTLE_TIME = 350
/**
 * Selectors that will be used as headings in the
 * table of content
 */
const SELECTOR: keyof HTMLElementTagNameMap = 'h2'
/**
 * Percentage of the screen to consider as part of the coming head element
 *
 *
 *   <h2>1. First heading</h2>
 *                  |
 *        <Out of the screen zone>
 *                  |
 *                  |
 * -------------------------------
 * |                |            |
 * |              30%            |
 * |                |            |
 * | <h2>2. Second heading</h2>  |   The active heading is the Second heading
 * |                             |   because the 30% is smaller than the threshold
 * |                             |   of 35%
 * |                             |
 * | <h2>3. Third heading</h2>   |
 * |                             |
 * |                             |
 * -------------------------------
 */
const THRESHOLD = 0.35

type Args = {
  initialHeadingsInfo?: HeadingInfo[]
  getTitle?: (node: HTMLElement) => string
  getDepth?: (node: HTMLElement) => number
}

/**
 *
 * @param deps This array should have a fixed size
 */
export function useToc(args: Args) {
  const { getTitle, getDepth, initialHeadingsInfo } = args

  const [active, setActive] = useState(-1)
  const [headings, setHeadings] = useState<HeadingInfo[]>(
    initialHeadingsInfo ?? [],
  )

  const [, setMinDepth] = useState<number>(0)

  useEffect(
    function defineHeadings() {
      const articleEl = document.querySelector('article')

      if (!articleEl) {
        return
      }

      const { newHeadings, newMinDepth } = getHeadingsInfo({
        getDepth,
        getTitle,
        articleEl,
        selector: SELECTOR,
      })

      setHeadings(newHeadings)
      setMinDepth(newMinDepth)
    },
    [getDepth, getTitle],
  )

  useEffect(
    function registerScrollCallback() {
      const articleEl = document.querySelector('article')

      if (!articleEl) {
        return
      }

      const scrollHandler = throttle(() => {
        const { newHeadings: curHeadings } = getHeadingsInfo({
          getDepth,
          getTitle,
          articleEl,
          selector: SELECTOR,
        })

        // Offsets need to be recomputed inside scrollHandler because
        // lazily-loaded content increases offsets as user scrolls down.
        const offsets = curHeadings.map(({ node }) => accumulateOffsetTop(node))
        const activeIndex = offsets.findIndex((offset) => {
          // `window.scrollY` is the amount of scroll of the window. and `window.innerHeight`
          // is the size of the window. So we're considering a portion of the
          // window as a scrolled region and then comparing it with the offset
          return offset > window.scrollY + THRESHOLD * window.innerHeight
        })

        setActive(activeIndex === -1 ? curHeadings.length - 1 : activeIndex - 1)
      }, THROTTLE_TIME)

      // We have to use the scroll event because IntersectionObserver doesn't
      // if the user clicks a heading link and the page, instead of smooth
      // scroll, goes directly to the heading. And since `scroll-behavior`
      // is not available on Safari, clicking on a heading would work on Safari
      window.addEventListener('scroll', scrollHandler, { passive: true })

      return () => window.removeEventListener('scroll', scrollHandler)
    },
    [getDepth, getTitle],
  )

  return {
    headings,
    activeHeading: active,
  }
}

type GetHeadingsInfoArgs = {
  articleEl: HTMLElement
  getTitle?: (node: HTMLElement) => string
  getDepth?: (node: HTMLElement) => number
  selector: keyof HTMLElementTagNameMap
}

function getHeadingsInfo(args: GetHeadingsInfoArgs) {
  const { articleEl, selector, getTitle, getDepth } = args

  const nodes = Array.from(
    articleEl.querySelectorAll<HTMLHeadingElement>(selector),
  )

  const newHeadings = nodes.map((node) => ({
    node,
    id: node.id,
    title: getTitle ? getTitle(node) : node.innerText,
    depth: getDepth ? getDepth(node) : Number(node.nodeName[1]),
  }))

  const newMinDepth = Math.min(...newHeadings.map((h) => h.depth))

  return { newMinDepth, newHeadings }
}

export type MobileDialogFooter = 'share' | 'tracker-cta'
