/**
 * Code from: https://github.com/getcircuit/web-packages/blob/0ea57db1d04bfee5d0256cd859355d7e6c8ad8f2/packages/web-utils/src/modules/throttleFn.ts
 */

type ThrottledFn<T extends GenericFunction> = (...args: Parameters<T>) => void

// Throttle with ensured final and immediate invocations
export function throttleFn<T extends GenericFunction>(
  callback: T,
  delay: number,
  {
    leading = true,
    trailing = true,
  }: {
    leading: boolean
    trailing: boolean
  } = { leading: true, trailing: true },
): ThrottledFn<T> {
  let queued: number | undefined
  let lastCall: number

  return function invokeFn(...args: Parameters<T>) {
    const now = Date.now()

    if (trailing) {
      window.clearTimeout(queued)
      queued = undefined
    }

    // first call or consecutives inside the allowes time window
    if (lastCall == null || now - lastCall >= delay) {
      if (leading || lastCall != null) callback(...args)
      lastCall = now
    } else {
      if (trailing) {
        queued = window.setTimeout(
          () => invokeFn(...args),
          delay - (now - lastCall || 0),
        )
      } else {
        queued = undefined
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericFunction = (...params: any[]) => any
