'use client'

import clsx from 'clsx'
import { Transition } from '@headlessui/react'
import { useState } from 'react'

import type { MultiLinkMenu, Navigation } from '$/models/navigation'
import { useBodyScrollLock } from '$/modules/hooks/useBodyScrollLock'
import './styles.css'

import { DesktopHeader, MobileHeader } from './HeaderRow.styled'

import type { DataSliceType, LinkProps } from '../types'

export type HeaderRowProps = {
  dataSliceType?: DataSliceType
  links: MultiLinkMenu[]
  primaryNavCTAs?: Navigation['ctas']
  logoLink: LinkProps
  talkToSales: boolean
  children: React.ReactNode
  pathname?: string
}

export function HeaderRow({
  dataSliceType,
  links,
  primaryNavCTAs,
  logoLink,
  talkToSales,
  children,
  pathname,
}: React.PropsWithChildren<HeaderRowProps>) {
  const [open, setOpen] = useState(false)

  useBodyScrollLock(open)

  const stickyButtonData = primaryNavCTAs?.find((cta) => cta.stickyText)

  const CTAList = primaryNavCTAs?.filter((cta) => cta.stickyText == null)

  return (
    <header
      data-slice-type={dataSliceType}
      className="header sticky top-0 z-header"
    >
      <div className="h-16 lg:h-20">
        <div
          className={clsx(
            'header-height-animation relative bg-white transition-height duration-200',
            {
              // we remove the outline border when the mobile menu is open
              'outline-none md:outline-offset-0 md:outline-gray-900/5': open,
              'outline outline-gray-900/5': !open,
            },
          )}
        >
          <DesktopHeader
            menuOpen={open}
            links={links}
            logoLink={logoLink}
            talkToSales={talkToSales}
            stickyButtonData={stickyButtonData}
            primaryNavCTAs={primaryNavCTAs}
            menuClickHandler={() => setOpen((prev) => !prev)}
            pathname={pathname}
          >
            {children}
          </DesktopHeader>
        </div>
      </div>
      <section className="relative">
        <Transition
          show={open}
          // we need to keep it mounted all the time to make sure that crawlers will be able to read our nav links
          // since the current flyout menu is removed from the DOM as a radix-ui design choice
          unmount={false}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="absolute -top-16 right-0 z-negative h-full w-full md:w-[23.4375rem] lg:hidden"
        >
          <MobileHeader
            menuOpen={open}
            onClose={() => setOpen(false)}
            stickyButtonData={stickyButtonData}
            links={links}
            logoLink={logoLink}
            talkToSales={talkToSales}
            CTAList={CTAList}
          />
        </Transition>
      </section>
    </header>
  )
}
